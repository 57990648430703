let topOffset = document.querySelector("#main-nav").offsetHeight;

let navbar = document.getElementById("wrapper-navbar");

let navDropdown = document.getElementById("navbarNavDropdown");
navbar.classList.add("fixed-top");
document.body.style.paddingTop = topOffset + "px";

//подсветка активных пунктов меню
let menuLinks = document.querySelectorAll("#main-menu a");

menuLinks.forEach((link) => {
	link.addEventListener("click", (e) => {
		menuLinks.forEach((el) => {
			el.classList.remove("active");
		});
		e.target.classList.add("active");
	});
});

//докрутка при переходе с вторичных страниц на главную
if (window.location.hash) {
	let myHash = location.hash.substring(1);
	const scrollTarget = document.getElementById(myHash);

	if (myHash[1] != undefined) {
		const elementPosition = scrollTarget.getBoundingClientRect().top;
		const offsetPosition = elementPosition - topOffset -20;

		history.pushState("", document.title, window.location.pathname);
		window.scrollBy({
			top: offsetPosition,
			behavior: "smooth",
		});
	}
}
// скрываем моб менюшку при клике вне меню
let main = document.querySelector(".main-content");

if (main !== null) {
	main.addEventListener("click", function () {
	
		if (navDropdown.classList.contains("show")) {
			navDropdown.classList.remove("show");
		}
	});
}
//прокрутка на главной странице
document.querySelectorAll('a[href^="#"]').forEach((link) => {
	link.addEventListener("click", function (e) {
		e.preventDefault();

		let href = this.getAttribute("href").substring(1);

		if (href !== "") {
			//скрываем моб менюшку при клике только на ссылки
			if (navDropdown.classList.contains("show")) {
				navDropdown.classList.remove("show");
			}
			const scrollTarget = document.getElementById(href);
			const elementPosition = scrollTarget.getBoundingClientRect().top;
			const offsetPosition = elementPosition - topOffset - 20;

			window.scrollBy({
				top: offsetPosition,
				behavior: "smooth",
			});
		}
	});
});
